import { Component, ElementRef, Input, OnChanges, SecurityContext, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FillMode, FillModeType } from './model/fill-mode.enum';
import { from } from 'rxjs';

import { IconSize } from './model/icon-size.enum';
import { LoggerService } from '@framework';

@Component({
  selector: 'ui-svg',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
})
export class SvgIconComponent implements OnChanges {
  @Input() size!: IconSize;
  @Input() icon!: string;
  @Input() life: number = 14;

  @Input() set mode(value: FillModeType) {
    this._mode = value;
  }
  get mode(): FillModeType {
    return this._mode || FillMode.Outline;
  }

  @Input() set fillColor(value: string) {
    value = value?.replace('fill', 'text');
    this._fillColor = value;
  }
  get fillColor(): string {
    return this._fillColor ? this._fillColor : '';
  }

  @Input() set strokeColor(value: string) {
    value = value?.replace('stroke', 'text');
    this._strokeColor = value;
  }
  get strokeColor(): string {
    return this._strokeColor ? this._strokeColor : '';
  }

  @Input() set styleClass(value: string) {
    this._styleClasses = value;
  }
  get styleClass() {
    const classes: string[] = [
      this._styleClasses,
      this.size ? this.size : '',
      this.mode === FillMode.Outline && !this.fillColor ? 'fill-none' : '',
      this.fillColor ? this.fillColor : '',
      this.strokeColor ? this.strokeColor : '',
    ];
    return classes.join(' ');
  }

  @Input() cache = true;

  get style(): string {
    return this.mode.charAt(0).toUpperCase() + this.mode.slice(1);
  }

  private _mode!: FillModeType;
  private _styleClasses!: string;
  private _fillColor?: string;
  private _strokeColor?: string;

  @ViewChild('ownRef') ownRef!: ElementRef;

  constructor(
    private sanitizer: DomSanitizer,
    private readonly logger: LoggerService,
  ) {}

  ngOnChanges(): void {
    const url = this.sanitizer.sanitize(SecurityContext.URL, this.getResourcePath(this.icon));

    from(this.getResource(url ?? '')).subscribe((value) => {
      this.ownRef.nativeElement.innerHTML = value;
    });
  }

  private getResourcePath(icon: string): string {
    return `/assets/icons/${this.style}/${icon}.svg`;
  }

  private async getResource(url: string): Promise<string> {
    return await caches.open('svg-cache').then((cache) => {
      return cache.match(url).then((response) => {
        if (!response) {
          return this.cacheFetched(url, cache);
        }

        const date = new Date(response?.headers.get('date') || '');
        if (Date.now() > date.getTime() + 1000 * 60 * 60 * 24 * this.life) {
          cache.delete(url);
          return this.cacheFetched(url, cache);
        }

        return response?.text();
      });
    });
  }

  private cacheFetched(url: string, cache: globalThis.Cache): Promise<string> {
    return fetch(url, {
      headers: {
        'Content-Type': 'image/svg+xml',
      },
    })
      .then((resp) => {
        if (!resp.headers.get('content-type')?.includes('image/svg+xml')) {
          this.logger.error('Failed to fetch resource => ' + url);
          return this.displayDefaultIcon();
        }
        if (this.cache && resp.ok) {
          cache.put(url, resp.clone());
        }
        return resp.text();
      })
      .catch((err: Error) => {
        this.logger.error('Failed to fetch resource => ' + url, err);
        return this.displayDefaultIcon();
      });
  }

  private displayDefaultIcon(): string {
    return '<svg viewBox="0 0 24 24" class="not-found" fill="none" stroke="red" xmlns="http://www.w3.org/2000/svg"><path d="M18.364 18.364C20.0518 16.6762 21 14.387 21 12C21 9.61303 20.0518 7.32383 18.364 5.63599C16.6762 3.94816 14.387 2.99994 12 2.99994C9.61303 2.99994 7.32383 3.94816 5.63599 5.63599M18.364 18.364C16.6762 20.0518 14.387 21 12 21C9.61303 21 7.32383 20.0518 5.63599 18.364C3.94816 16.6762 2.99994 14.387 2.99994 12C2.99994 9.61303 3.94816 7.32383 5.63599 5.63599M18.364 18.364L5.63599 5.63599" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>';
  }
}
