import { DCAbstractStore, Loadable } from './dc-abstract.store';
import { map, switchMap } from 'rxjs/operators';

import { LoggerService } from '../../logger/logger.service';
import { Observable } from 'rxjs';
import { Signal, inject } from '@angular/core';
import { tapResponse } from '@ngrx/component-store';
import { toSignal } from '@angular/core/rxjs-interop';

export interface Entity<E> extends Loadable {
  entity: E | null;
}

export abstract class DCAbstractEntityStore<T extends Entity<E>, E> extends DCAbstractStore<T> {
  protected readonly loggerService = inject(LoggerService);

  protected constructor(defaultState: T) {
    super(defaultState);
  }

  abstract loadEntity$(filter?: any): Observable<E>;

  readonly entity$: Observable<E | null> = this.select((state) => state.entity);
  readonly entity: Signal<E | null> = this.selectSignal((state) => state.entity);

  // ViewModel for the component
  readonly vm$ = this.select(this.entity$, this.callStateVm$, (entity, vm) => ({
    entity,
    loading: vm.loading,
    storing: vm.storing,
    error: vm.error,
  }));

  readonly vm = toSignal(this.vm$);

  // UPDATERS
  readonly setEntity = this.updater((state, entity: E) => ({
    ...state,
    entity,
    error: '',
  }));

  /**
   * @deprecated The method should not be used - use dispatch method. This method is for backward compatibility.
   */
  readonly loadEntity = this.effect((filter$: Observable<any>) => filter$.pipe(map((filter) => this.dispatch(filter))));

  // EFFECTS
  readonly dispatch = this.effect((filter$: Observable<any>) =>
    filter$.pipe(
      switchMap((filter) => {
        this.setLoading();
        return this.loadEntity$(filter).pipe(
          tapResponse(
            (entity) => {
              this.setLoaded();
              this.setEntity(entity);
            },
            (e: Error | any) => {
              const pgError = e.error;
              this.updateError(pgError?.code ? this.getPgRestErrorMessage(pgError) : e.message);
              this.loggerService.error(`Failed to load entity for input: ${JSON.stringify(filter)}`, e);
            },
          ),
        );
      }),
    ),
  );

  // @ts-ignore
  private getPgRestErrorMessage({ message, hint }): string {
    return `Popis: ${message}, Nápověda: ${hint}`;
  }
}
