export const permissions = {
  objectSearch: 'coreAccess-objectSearch',
  insurance: 'coreAccess-insurance',
  virtualInspection: 'coreAccess-virtualInspection',
  koka: 'koka',
  partnerSearch: 'partnerSearch',
  flw: {
    flwTask: 'coreAccess-flowableTasks',
    flowableTasksManagement: 'coreAccess-flowableTasksManagement',
    flowableTasksResolverAdmin: 'coreAccess-flowableTasksResolverAdmin',
  },
  lossEvent: {
    administrationTasks: 'coreAccess-lossEventSettlement-processAdministrationTasks',
    status: 'coreAccess-lossEventSettlement-statusChange',
    kapu: 'coreAccess-lossEventSettlement-solverRoleChange',
    assessor: 'coreAccess-lossEventSettlement-liquidatorRoleChange',
    loadAccountPartners: 'coreAccess-lossEventSettlement-processHumanTasks',
    coreAccessLossEventSettlementOverview: 'coreAccess-lossEventSettlement-overview',
    loadUsers: 'coreAccess-lossEventSettlement-processHumanTasks',
    saveReserve: 'claim-registration-nc-save-reserve',
    coreAccessLossEventSettlementReserveManagement: 'coreAccess-lossEventSettlement-reserveManagement',
    coreAccessLossEventSettlementBenefitPaymentManagement: 'coreAccess-lossEventSettlement-benefitPaymentManagement',
  },
  subclaim: {
    editStatus: 'coreAccess-lossEventSettlement-subclaimManagement',
  },
  salesManagement: {
    contacts: 'coreAccess-salesManagement-contacts',
    edit: 'coreAccess-salesManagement-edit',
  },
  feedback: {
    feedbackMyTeam: 'coreAccess-feedback-myTeam',
    feedbackCompany: 'coreAccess-feedback-company',
    editCompetency: 'coreAccess-feedback-editCompetency',
    editSupporter: 'coreAccess-editSupporter',
  },
  gallery: {
    coreAccessShowMapCompletionDocumentGallery: 'coreAccess-showMap-completionDocumentGallery',
  },
  commission: {
    commissionDisplay: 'coreAccess-commissionDisplay',
    paidCommissions: 'coreAccess-paidCommissionsDisplay',
    keeperChange: 'coreAccess-FM-commissionKeeperChange',
  },
  payments: {
    bulkPayment: 'coreAccess-bulkPayment',
    transactionManagement: 'coreAccess-transactionManagement',
    paymentOrderExport: 'coreAccess-paymentOrderExport',
    postalSlipsExport: 'coreAccess-FM-postalSlipsExport',
    overPaymentRefund: 'coreAccess-BP-overPaymentRefundTask',
  },
  finance: {
    cancel: 'coreAccess-FM-cancelPaymentOrder',
    cancelDirectDebitBenefit: 'coreAccess-FM-cancelPaymentOrder-directDebitBenefit',
    cancelExtra: 'coreAccess-FM-cancelExportedPaymentOrder',
    edit: 'coreAccess-FM-editPaymentOrder',
    editAmount: 'coreAccess-FM-editPaymentOrderCommissionPayout',
    fixReturnedPaymentOrder: 'coreAccess-FM-fixReturnedPaymentOrder',
    commissionStatementManagement: 'coreAccess-commissionStatement-management',
    importBordero: 'coreAccess-FM-importBorderoPayments',
  },
  brokers: 'coreAccess-brokers',
  fleet: {
    addVehicle: 'coreAccess-fleets-addVehicle',
    createOffer: 'coreAccess-fleets-createOffer',
    manualActivation: 'coreAccess-fleets-policyActivation',
    manualRejection: 'coreAccess-fleets-policyRejection',
    superPolicyManagement: 'coreAccess-fleets-superPolicyManagement',
    policyCancellation: 'coreAccess-fleets-policyCancellation',
    vehicleAttributeChange: 'coreAccess-fleet-vehicleAttributeChange',
  },
  productFruits: 'coreAccess-productFruits',
};
